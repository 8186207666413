import styled from 'styled-components';
import { Select as AntSelect } from 'antd';
import { Dimension } from 'interfaces/interfaces';
import { normalizeProp } from 'utils/utils';
import { FONT_WEIGHT, BORDER_RADIUS, FONT_SIZE } from '@app/styles/themes/constants';

export interface SelectProps {
  width?: Dimension;
  shadow?: boolean;
}

export const Select = styled(AntSelect).withConfig({
  shouldForwardProp: (prop) => !['shadow', 'width'].includes(prop),
})<SelectProps>`
  width: ${(props) => props.width && normalizeProp(props.width)};
  font-size: 12px;
  font-weight: ${FONT_WEIGHT.medium};

  box-shadow: ${(props) => props.shadow && 'var(--box-shadow)'};

  &.ant-select-borderless {
    background: var(--secondary-background-color);
    font-size: 12px;
    border-radius: ${BORDER_RADIUS};
  }

  .ant-select-selection-placeholder {
    font-size: 12px;
    color: var(--text-main-color);
  }

  .ant-select-arrow {
    color: var(--text-main-color);
    font-size: 12px;
  }

  &.ant-select-multiple.ant-select-sm .ant-select-selection-item {
    height: 0.875rem;
    line-height: 50px;
    font-size: 12px;
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }

  &.ant-select-multiple .ant-select-selector {
    padding: 0.5px 11.4px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 50px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--disabled-color);
  }

  .ant-select-clear {
    color: var(--disabled-color);
  }
  .ant-select-selection-item-remove {
    color: var(--icon-color);
    &:hover {
      color: var(--icon-hover-color);
    }
  }
  .ant-select-item-option-disabled {
    color: var(--disabled-color);
  }
`;
