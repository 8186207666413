/* eslint-disable prettier/prettier */
import { gql } from '@apollo/client';

export const GET_Login = gql`
  query ($email: String!, $senha: String!) {
    login (email: $email, senha: $senha) {
      accessToken
      nome
    }
  }
`;
