import { ApolloClient, InMemoryCache, createHttpLink, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { setContext } from '@apollo/client/link/context';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export const getStorageData = (key: string): string => {
  return localStorage.getItem(key);
};

export const setStorageData = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export function isRefreshNeeded(token?: string | null) {
  if (!token) {
    return { valid: false, needRefresh: true };
  }

  const decoded = jwt_decode<JwtPayload>(token);

  if (!decoded) {
    return { valid: false, needRefresh: true };
  }
  if (decoded.exp && Date.now() >= decoded.exp * 1000) {
    return { valid: false, needRefresh: true };
  }
  return { valid: true, needRefresh: false };
}

const refreshAuthToken = async () => {
  //   const refreshToken = getStorageData(STORAGE_CONTANTS.REFRESHTOKEN);

  //   const newToken = await client
  //     .mutate({
  //       mutation: gql`
  //         mutation RefreshToken($refreshAccessTokenRefreshToken: String!) {
  //           refreshAccessToken(refreshToken: $refreshAccessTokenRefreshToken) {
  //             accessToken
  //             status
  //           }
  //         }
  //       `,
  //       variables: { refreshAccessTokenRefreshToken: refreshToken },
  //     })
  //     .then(res => {
  //       const newAccessToken = res.data?.refreshAccessToken?.accessToken;
  //       setStorageData(STORAGE_CONTANTS.AUTHTOKEN, newAccessToken, true);
  //       return newAccessToken;
  //     });

  //   return newToken;
  return '';
};

const apolloHttpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL, //process.env.REACT_APP_API_URL,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const apolloAuthLink = setContext(async (request, { headers }) => {
  if (request.operationName !== 'RefreshToken') {
    let token = getStorageData('@AutoSenderGestao/Token');
    const shouldRefresh = isRefreshNeeded(token);

    if (token && shouldRefresh.needRefresh) {
      const refreshPromise = await refreshAuthToken();

      if (shouldRefresh.valid === false) {
        token = await refreshPromise;
      }
    }

    if (token) {
      return {
        headers: {
          ...headers,
          'x-access-token': `${token}`,
        },
      };
    }
    return { headers };
  }

  return { headers };
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: apolloAuthLink.concat(errorLink).concat(apolloHttpLink),
  cache: new InMemoryCache(),
});

// const client = new ApolloClient({
//   uri: 'http://192.168.15.33:5052/graphql',
//   cache: new InMemoryCache(),
// });

export { client };
