import { httpApi } from '@app/api/http.api';
import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';
import { GET_Login } from '@app/graphql/auth/queries';
import { client } from './apollo';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export const login = async (loginPayload: LoginRequest): Promise<LoginResponse> => {
  return client
    .query({
      fetchPolicy: 'network-only',
      query: GET_Login,
      variables: {
        email: loginPayload.email,
        senha: loginPayload.password,
      },
    })
    .then(({ data }) => {
      const { login } = data;
      return {
        token: login.accessToken,
        user: {
          id: 1,
          firstName: login.nome,
          lastName: 'Johnson',
          imgUrl: '',
          userName: '@john1989',
          email: {
            name: 'suporte@autosender.com.br',
            verified: false,
          },
          phone: {
            number: '',
            verified: true,
          },
          sex: 'male',
          birthday: '',
          lang: 'en',
          country: '',
          city: '',
          address1: '',
          zipcode: 0,
          website: '',
          socials: {
            twitter: '',
            facebook: '',
            linkedin: '',
          },
        },
      };
    });
};
// httpApi.post<LoginResponse>('login', { ...loginPayload }).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('signUp', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);
